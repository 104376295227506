



















































import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import DeleteModal from '@/components/organisms/OrgUserSetting/DeleteModal.vue'
import Role from '@/components/atoms/OrgUser/Role.vue'
import Status from '@/components/atoms/OrgUser/Status.vue'
import OrganizationUsersStore, {
  OrganizationOwner,
  OrganizationViewer,
  Role as OrgUserRole,
  Status as OrgUserStatus,
} from '@/store/OrganizationUsers'

@Component({ components: { VABox, DeleteModal, Role, Status } })
export default class extends Vue {
  isDeleted(status: string): boolean {
    return status === OrgUserStatus.DELETED
  }

  name(name: string, status: string): string {
    return this.isDeleted(status) ? '-' : name
  }

  email(email: string, status: string): string {
    return this.isDeleted(status) ? '-' : email
  }

  get orgOwners(): OrganizationOwner[] {
    return OrganizationUsersStore.organizationUsers.filter((orgUser) => orgUser.role === OrgUserRole.OWNER)
  }

  get orgViewers(): OrganizationViewer[] {
    return OrganizationUsersStore.organizationUsers.filter((orgUser) => orgUser.role === OrgUserRole.VIEWER)
  }
}
