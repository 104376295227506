























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/molecules/Modal.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import organizationUsersStore from '@/store/OrganizationUsers'

@Component({ components: { Modal, Spinner } })
export default class extends Vue {
  @Prop({ type: String, required: true }) orgViewerId!: string

  isSuccess = false
  isLoading = false
  errorMessage = ''

  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
    this.isSuccess = false
  }

  async deleteOrgUser(): Promise<void> {
    this.isLoading = true

    try {
      await organizationUsersStore.deleteOrgViewer(this.orgViewerId)
      await organizationUsersStore.fetchOrganizationUsers()
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }
}
