




import { Role } from '@/store/OrganizationUsers'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: String }) orgUserRole?: string
  get role(): string {
    switch (this.orgUserRole) {
      case Role.OWNER:
        return '所有者'
      case Role.VIEWER:
        return '閲覧者'
      default:
        return '-'
    }
  }
}
