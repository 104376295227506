





































import Spinner from '@/components/atoms/Spinner.vue'
import Modal from '@/components/molecules/Modal.vue'
import organizationUsersStore from '@/store/OrganizationUsers'
import _ from 'lodash'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Modal, Spinner } })
export default class extends Vue {
  isSuccess = false
  isLoading = false
  email = ''
  errorMessage = ''
  isErrorHidden = true

  get userName(): string {
    return organizationUsersStore.invitedCheckedViewer.name
  }

  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
    this.isSuccess = false
  }

  checkEmail(): void {
    organizationUsersStore.setInvitedCheckedViewer({ name: '' })
    this.errorMessage = ''

    const checkEmailDebounce = _.debounce(() => {
      if (this.email === '') {
        this.errorMessage = ''
        return
      }

      organizationUsersStore
        .checkEmail({ email: this.email })
        .then(() => {
          this.errorMessage = ''
        })
        .catch((e: Error) => {
          this.errorMessage = e.message
        })
    }, 500)
    checkEmailDebounce()
  }
  async addUser(): Promise<void> {
    if (this.userName === '') {
      return
    }

    this.isLoading = true
    try {
      await organizationUsersStore.inviteViewer({ email: this.email })
      await organizationUsersStore.fetchOrganizationUsers()

      this.email = ''
      organizationUsersStore.setInvitedCheckedViewer({ name: '' })
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }

  focusEmailInput(): void {
    this.isErrorHidden = true
  }

  blurEmailInput(): void {
    this.isErrorHidden = false
  }
}
