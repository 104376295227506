










import OrgUserTable from '@/components/organisms/OrgUserSetting/OrgUserTable.vue'
import AddModal from '@/components/organisms/OrgUserSetting/AddModal.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import organizationUsersStore from '@/store/OrganizationUsers'
import menuStore from '@/store/Menu'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { OrgUserTable, AddModal, Spinner } })
export default class extends Vue {
  isLoading = false

  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })

    this.isLoading = true
    try {
      await Promise.all([organizationUsersStore.fetchOrganizationUsers()])
    } finally {
      this.isLoading = false
    }
  }
}
